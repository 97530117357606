"use client";

import { useContext, useState } from "react";

import * as Sentry from "@sentry/browser";
import classNames from "classnames";

import type { UserEntity } from "@/hl-common/types/api/Users";

import { ModalContext } from "@/utils/ModalContext";
import { UserContext } from "@/utils/UserContext";
import { useForm } from "@/utils/useForm";

import { ArrowRightIcon, FlagIcon } from "@heroicons/react/20/solid";

import { CourseContext } from "@/utils/CourseContext";
import { ModuleContext } from "@/utils/ModuleContext";
import Loader from "./Loader";
import Message from "./Message";
import { Button } from "./forms/Button";
import Input from "./forms/Input";
import TextArea from "./forms/TextArea";

export const FeedbackTinyButton = ({
  iconOnly,
  iconColor,
}: {
  iconOnly?: boolean;
  iconColor?: string;
}) => {
  const { setModal } = useContext(ModalContext);

  return (
    <Button
      small
      secondary={!iconOnly}
      minimal={iconOnly}
      inline
      className={classNames("w-8 flex items-center justify-center", {
        "rounded-full shadow-sm h-8": !iconOnly,
      })}
      title="Report an issue"
      onClick={() => setModal(<FeedbackForm inModal />)}
    >
      <FlagIcon
        fill={iconColor ? iconColor : "currentColor"}
        className="h-5 w-5"
      />
    </Button>
  );
};

export const FeedbackButton = () => {
  const { setModal } = useContext(ModalContext);

  return (
    <Button onClick={() => setModal(<FeedbackForm inModal />)}>
      <span>Report an issue</span> <FlagIcon className="inline ml-1 h-5 w-5" />
    </Button>
  );
};

// this wrapper waits to initialize the form until the user is loaded
export const FeedbackForm = ({
  inModal,
}: {
  inModal: boolean;
}) => {
  const { userLoading } = useContext(UserContext);
  if (userLoading) {
    return <Loader centered />;
  }

  return <FeedbackFormInner inModal={inModal} />;
};

const FeedbackFormInner = ({
  inModal,
}: {
  inModal: boolean;
}) => {
  const { card, module } = useContext(ModuleContext);
  const { course } = useContext(CourseContext);

  const { close } = useContext(ModalContext);
  const { user } = useContext(UserContext);
  const [showThanks, setShowThanks] = useState(false);
  const sentry = Sentry.getCurrentHub().getClient();

  const { handleSubmit, handleInputChange, fields } = useForm(
    {
      event_id: "",
      name: makeName(user),
      email: user.email,
      comments: "",
    },
    (feedback) => {
      if (feedback.comments) {
        const formattedContext = JSON.stringify(
          {
            courseId: course?.id,
            moduleId: module?.id,
            cardId: card?.id,
          },
          null,
          2,
        );

        feedback.comments += `\n\nUser context: ${formattedContext}`;
        feedback.event_id = Sentry.captureMessage("User Feedback");

        Sentry.captureUserFeedback(feedback);
        setShowThanks(true);
      } else {
        close();
      }
    },
  );

  const gaveContactInfo = fields.name && fields.name !== anon;

  if (showThanks) {
    return (
      <div className="min-w-80">
        <p className="mb-2">
          Thank you for your feedback!
          {gaveContactInfo && (
            <>
              <br />
              We may be in touch with regards to this issue.
            </>
          )}
        </p>
        {inModal && (
          <Button secondary onClick={close}>
            Close
          </Button>
        )}
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      {!sentry && (
        <Message warning>
          Sentry is not initialized. Feedback will not be submitted.
        </Message>
      )}
      {/* give anonymous users the chance to include contact details */}
      {!user.id && (
        <Input
          label="Contact info"
          required
          placeholder="WhatsApp phone number, email, etc."
          name="name"
          onChange={handleInputChange}
        />
      )}
      <TextArea
        autoFocus
        label="Report a problem"
        name="comments"
        rows={6}
        cols={40}
        minLength={20}
        onChange={handleInputChange}
        placeholder="What went wrong? How can we improve?"
      />
      <Button type="submit">
        Submit Feedback <ArrowRightIcon className="inline ml-1 w-5 h-5" />
      </Button>
    </form>
  );
};

const anon = "Anonymous";

const makeName = (user: UserEntity) => {
  if (!user.id) {
    return anon;
  }

  return [user.first, user.last, `(${user.id})`].filter(Boolean).join(" ");
};
